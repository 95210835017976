import { graphql } from 'gatsby'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import BlogPosts from '~/components/BlogPosts'
import Container from '~/components/Container'
import Layout from '~/components/layouts'
import MainHero from '~/components/MainHero'
import Seo from '~/components/Seo'
import TranslatedOptions from '~/components/TranslatedOptions'
import useI18n, {
  AUTHOR_POSTS_NO_RESULTS,
  AUTHOR_POSTS_WELCOME,
  AUTHOR_TRANSLATED_OPTIONS,
} from '~/utils/useI18N'

const Author = ({
  data: {
    markdownRemark: { frontmatter, html },
  },
  pageContext: { availableLanguages, lang, link, posts },
}) => {
  const data = useMemo(
    () => posts.filter((post) => post.frontmatter.author === frontmatter.title),
    [posts, frontmatter.title]
  )
  return (
    <>
      <Seo blogMetadata={frontmatter} lang={lang} link={link} />
      <Layout lang={lang}>
        <MainHero
          descriptionMarkdown={html}
          image={frontmatter?.profileImage?.childImageSharp}
          title={frontmatter.title}
        />
        <Message author={frontmatter.title} />
        <BlogPosts author={frontmatter.title} data={data} noResultsKey={AUTHOR_POSTS_NO_RESULTS} />
        <TranslatedOptions
          availableLanguages={availableLanguages}
          descriptionKey={AUTHOR_TRANSLATED_OPTIONS}
          showContainer
          marginTop
          replaceKey="[[AUTHOR]]"
          replaceValue={frontmatter.title}
        />
      </Layout>
    </>
  )
}

export default Author

const Message = ({ author }) => {
  return <ContainerMessage>{useI18n(AUTHOR_POSTS_WELCOME, { AUTHOR: author })}</ContainerMessage>
}

const ContainerMessage = styled(Container)`
  font-size: 1.5rem;
  margin-bottom: ${({ theme: { spacing } }) => spacing.lg};

  &:after {
    color: ${({ theme: { colors } }) => colors.pink};
    content: '\\2935';
    margin-left: 6px;
    margin-top: 6px;
    position: absolute;
  }
`

// dynamic page query, must occur within each post context
// $slug is made available by context from createPages call in gatsby-node.js
export const getAuthorData = graphql`
  query($slug: String!, $lang: String!) {
    markdownRemark(fields: { slug: { eq: $slug }, lang: { eq: $lang } }) {
      frontmatter {
        description: seoDescription
        title
        profileImage {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      html
    }
  }
`
