import React from 'react'
import styled from 'styled-components'

import AuthorLink from '~/components/AuthorLink'
import Container from '~/components/Container'
import DotList from '~/components/DotList'
import Link from '~/components/Link'
import { upperFirst } from '~/utils/strings'
import styles from '~/utils/styles'
import useI18n, { NO_POSTS, READING_TIME } from '~/utils/useI18N'

const List = ({ data = [], showAuthor }) =>
  data.map((node) => (
    <Article key={node.fields.slug}>
      <header>
        <Title>
          <Link to={`/${node.fields.slug}`} key={node.id}>
            {node.frontmatter.title}
          </Link>
        </Title>
        <Date>
          <DotList
            list={[
              showAuthor && <AuthorLink author={node.frontmatter.author} key="author" />,
              upperFirst(node.frontmatter.date),
              useI18n(READING_TIME, { MIN: Math.ceil(node.fields.readingTime.minutes) }),
            ]}
          />
        </Date>
      </header>
      <Excerpt>{node.frontmatter.description || node.excerpt}</Excerpt>
    </Article>
  ))

const BlogPosts = ({ author, data, noResultsKey = NO_POSTS, showAuthor = false }) => {
  return (
    <section>
      <Container>
        {data.length ? (
          <List data={data} showAuthor={showAuthor} />
        ) : (
          <Title>{useI18n(noResultsKey, { AUTHOR: author })}</Title>
        )}
      </Container>
    </section>
  )
}

const Article = styled.article`
  display: block;
  font-size: 1rem;

  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }) => spacing.lg};
  }
`

const Title = styled.h2`
  display: flex;
  font-size: 2rem;
  margin-bottom: ${({ theme: { spacing } }) => spacing.xs};

  a {
    color: ${({ theme: { colors } }) => colors.blue};
    text-decoration: none;

    &:focus,
    &:hover {
      color: ${({ theme: { colors } }) => colors.darkBlue};
    }
  }

  ${styles.breakpoints.md} {
    margin-bottom: ${({ theme: { spacing } }) => spacing.sm};
  }
`

const Date = styled.small`
  color: ${({ theme: { colors } }) => colors.gray};
  display: block;
  font-family: 'Source Code Pro', monospace;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: ${({ theme: { spacing } }) => spacing.sm};
`

const Excerpt = styled.p`
  font-size: 1.4rem;
  line-height: 1.5;
`

export default BlogPosts
